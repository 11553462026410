import cn from 'classnames';

interface Props {
  className?: string;
  rounded?: boolean;
  size?: 'small' | 'medium';
  type?: 'General' | 'Limited' | 'Discount' | 'Bestseller';
}

/**
 * Badge component
 */
const Badge: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  rounded,
  size = 'small',
  type = 'General',
}) => {
  return (
    <div
      className={cn(
        'h-auto shrink-0 items-center justify-center py-0.5 text-center',
        size === 'small' && 'px-1.5 text-xs',
        size === 'medium' && 'px-3 text-sm',
        type === 'Discount' && 'bg-red-base text-white',
        type === 'Limited' && 'bg-blue-50 text-blue-brand',
        type === 'Bestseller' && 'grow bg-blue-brand text-white lg:grow-0',
        type === 'General' && 'bg-gray-100 text-gray-800',
        rounded && 'rounded-full',
        className
      )}
    >
      <span className="block">{children}</span>
    </div>
  );
};
export default Badge;
