import laggy from 'api/middleware/laggy';
import { CollectionResponse, fetchCollectionProducts } from 'api/product';
import useShouldProductBeHidden from 'components/legacy-product-tile/use-should-product-be-hidden';
import { CollectionSorting } from 'constants/collection';
import { SegmentationIds, isCatSegmentation } from 'constants/segmentation';
import { usePetContext } from 'contexts/pet';
import { useSegmentationContext } from 'contexts/segmentation';
import { useSpecialAbTestContext } from 'contexts/special-ab-test-provider';
import useTest4187 from 'hooks/common/use-test-4187';
import useAdditionalProducts from 'hooks/test/use-test-5830/use-additional-products';
import useSegmentationData from 'hooks/test/use-test-5830/use-segmentation-data';
import {
  CatNutritionalNeedsTags,
  FilterConfig,
  NutritionalNeedsTags,
} from 'interfaces/collection-filter';
import orderBy from 'lodash/orderBy';
import { CollectionProduct } from 'models/collection/collection';
import {
  Collection,
  transformCollection,
} from 'models/collection/transformCollection';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import { slugify } from 'utils/strings';
import { typedKeys } from 'utils/type-helper';
import useJudgeMeReviews from '../hooks/use-judge-me-reviews';
import FILTER_CONFIG from './filter-config';
import { useVisibleProductList } from './visible-products';

interface PropTypes {
  collection?: CollectionResponse;
  collectionHandle: string;
  sortBy?: CollectionSorting;
  tags?: string[];
  children: React.ReactNode;
  swrOptions?: SWRConfiguration;
  bestSellers?: { [key: number]: string[] };
}

export interface CollectionContextTypes {
  activeAllergyFilter: string[];
  appliedAllergyFilter: string[];
  appliedFilter: string[];
  collection: Collection;
  filterConfig: FilterConfig | undefined;
  isValidating: boolean;
  limitOfProductsToShow?: number;
  sorting: CollectionSorting;
  visibleProducts: CollectionProduct[];
  bestSellers: { [key: number]: string[] };
  onSortingChange: (sorting: CollectionSorting) => void;
  resetFilter: () => void;
  setLimitOfProductsToShow: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
  toggleActiveAllergyFilter: (value: string) => void;
  toggleAllergyFilter: (value: string) => void;
  toggleFilter: (value: string) => void;
  /** The length of products, which is independent of limitOfProductsToShow */
  productsLength: number;
}

export const CollectionContext = React.createContext<CollectionContextTypes>(
  {} as CollectionContextTypes
);

/**
 * Map of filter to segmentation
 */
const SEGMENTS_FILTER_MAP: {
  [key in NutritionalNeedsTags | CatNutritionalNeedsTags]:
    | SegmentationIds
    | Array<SegmentationIds>;
} = {
  'bestimmte-erkrankung': 'disease',
  'haut-and-fellprobleme': 'skin-fur',
  'sehr-wahlerisch': [
    SegmentationIds.PickyEater,
    SegmentationIds.CatPickyEater,
  ],
  'senior-7-jahre': 'senior',
  'unvertraglichkeiten-allergien': 'intolerance',
  verdauungsprobleme: [SegmentationIds.Digestion, SegmentationIds.CatDigestion],
  welpe: 'welpe',
  ubergewicht: [SegmentationIds.Overweight, SegmentationIds.CatOverweight],
  steriliziert: SegmentationIds.Sterilized,
  freiganger: SegmentationIds.Outdoor,
  kitten: SegmentationIds.Kitten,
  senior: SegmentationIds.CatSenior,
};

/**
 * Filter value set for segmentation
 */
const SEGMENT_FILTERS_SET = new Set(Object.keys(SEGMENTS_FILTER_MAP));

const sortCollection = (
  collection: Collection,
  sorting: CollectionSorting
): Collection => {
  let sortedProducts = collection.products;

  if (sorting === CollectionSorting.TITLE) {
    sortedProducts = orderBy(
      collection.products,
      (product) => product.title.trim(),
      'asc'
    );
  }

  if (
    [
      CollectionSorting.PRICE_ASCENDING,
      CollectionSorting.PRICE_DESCENDING,
    ].includes(sorting)
  ) {
    sortedProducts = orderBy(
      collection.products,
      (product) => parseFloat(product.variants[0].priceV2.amount),
      sorting === CollectionSorting.PRICE_ASCENDING ? 'asc' : 'desc'
    );
  }

  if (sorting === CollectionSorting.NEW) {
    sortedProducts = orderBy(
      collection.products,
      (product) => new Date(product.createdAt),
      'asc'
    );
  }

  if (sorting === CollectionSorting.BEST_SELLING) {
    sortedProducts = orderBy(collection.products, () => 0, 'asc');
  }

  return { ...collection, products: sortedProducts };
};

/**
 * Collection Context provider.
 * Filter values should be updated via only url updates.
 * so that always the flow is streamlined in url -> appliedFilter.
 */
export const CollectionProvider: React.FC<PropTypes> = ({
  children,
  tags: initialTags = [],
  collection: collectionDefault,
  collectionHandle,
  sortBy,
  swrOptions = {},
  bestSellers,
}) => {
  const { current } = usePetContext();
  const router = useRouter();
  const isCDP = useMemo(
    () => router.pathname === '/collections/[...all]',
    [router.pathname]
  );

  const { shouldHideFnFactory, getAdditionalProductHandles } =
    useSpecialAbTestContext();
  const shouldHide = shouldHideFnFactory(collectionHandle);
  const additionalProductHandles =
    getAdditionalProductHandles(collectionHandle);

  const { data: additional } = useAdditionalProducts({
    additionalProductHandles,
  });

  const { data: additionalSeg } = useSegmentationData({
    productHandles: additionalProductHandles,
  });

  /**
   * Merge bestSeller data with additional data
   */
  useEffect(() => {
    if (additionalSeg && bestSellers) {
      additional?.forEach((p) => {
        const segmentDataByHandle = additionalSeg[p.handle];
        if (segmentDataByHandle) {
          const bestSeller = typedKeys(segmentDataByHandle).filter(
            (segmentKey) => segmentDataByHandle[segmentKey]?.isBestSeller
          );
          bestSellers[p.id] = bestSeller;
        }
      });
    }
  }, [additional, additionalSeg, bestSellers]);

  const [limitOfProductsToShow, setLimitOfProductsToShow] = useState<number>();

  /**
   * Refetch Data on client side so we always get
   * up to date products. If we, at some point
   */
  const { data, isValidating } = useSWR<CollectionResponse>(
    collectionHandle,
    fetchCollectionProducts,
    {
      fallback: { [collectionHandle]: collectionDefault },
      use: [laggy],
      // Revalidation is done in useOptimizeInterceptor time-aligned with audience header strings for CDP
      // and revalidation should be triggered when audience is updated so that personalized price is reflected
      revalidateOnMount: false,
      ...swrOptions,
    }
  );

  useJudgeMeReviews({ collection: data });

  const transformedCollection = React.useMemo(() => {
    if (!data) {
      return {
        title: '',
        products: [],
      };
    }

    return transformCollection({
      collection: data,
    });
  }, [data]);

  const filterConfig = useMemo(() => {
    return collectionHandle ? FILTER_CONFIG[collectionHandle] : undefined;
  }, [collectionHandle]) as CollectionContextTypes['filterConfig'];

  const { shouldHideProduct } = useTest4187();
  const shouldHideFn = useShouldProductBeHidden();
  const filteredCollection = useMemo(() => {
    const products = additional
      ? [...additional, ...transformedCollection?.products]
      : [...transformedCollection?.products];

    return {
      ...transformedCollection,
      products: products
        .filter((product) => !shouldHideProduct(product))
        .filter((product) => !shouldHideFn(product.id))
        .filter((product) => shouldHide(product.handle)),
    };
  }, [
    additional,
    shouldHide,
    shouldHideFn,
    shouldHideProduct,
    transformedCollection,
  ]);

  const [sorting, setSorting] = useState<CollectionSorting>(
    CollectionSorting.DEFAULT
  );

  const collection = useMemo(() => {
    return sortCollection(filteredCollection, sorting);
  }, [sorting, filteredCollection]);

  useEffect(() => {
    setSorting(sortBy || CollectionSorting.DEFAULT);
  }, [sortBy, data]);

  /**
   * Filter Logic
   */
  const [appliedFilter, setAppliedFilter] = useState<string[]>(
    initialTags.map((tag: string) => slugify(tag))
  );

  const [appliedAllergyFilter, setAppliedAllergyFilter] = useState<string[]>(
    []
  );

  // Since the allregy filter also include a value that is toggled in the standard way,
  // we need to keep track of the active allergy filter separately.
  const [activeAllergyFilter, setActiveAllergyFilter] = useState<string[]>([]);
  const toggleActiveAllergyFilter = useCallback(
    (value: string) => {
      const newFilter = activeAllergyFilter.includes(value)
        ? activeAllergyFilter.filter((filter) => filter !== value)
        : [...activeAllergyFilter, value];
      setActiveAllergyFilter(newFilter);
    },
    [activeAllergyFilter]
  );

  /**
   * Apply filter values via url params.
   */
  useEffect(() => {
    if (!router.isReady) return;
    const filterStr = Array.isArray(router.query.all)
      ? router.query.all[1]
      : null;
    if (filterStr) {
      setAppliedFilter(filterStr.split('+').map((tag: string) => slugify(tag)));
    } else {
      setAppliedFilter([]);
    }
  }, [router.isReady, router.query.all]);

  const visibleProducts = useVisibleProductList(
    collection,
    appliedFilter,
    appliedAllergyFilter,
    filterConfig
  );

  /**
   * Due to the time lag between url update and applied filter update,
   * applied filter are previous values even after url is updated.
   * so here, we reset values when it's a non-shallow route change
   * */
  useEffect(() => {
    const handleRouteChange = (url, { shallow }): void => {
      if (!shallow) {
        setAppliedFilter([]);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  const requestURLUpdate = useCallback(
    (filters: string[]) => {
      const filtersPathPart = filters.length > 0 ? '/' + filters.join('+') : '';
      router.replace(
        `/collections/${collectionHandle}${filtersPathPart}${
          router.query.sortBy ? `?sortBy=${router.query.sortBy}` : ''
        }`,
        undefined,
        { shallow: true }
      );
    },
    [collectionHandle, router]
  );

  const { pushSegmentationFactory } = useSegmentationContext();

  const initialFilterClick = useRef(false);

  /**
   * Toggles a filter value and updates applied filters and segmentation.
   * Updates applied filters and segmentation based on whether the filter value is added or removed.
   * For Collection pages, it updates the URL; otherwise, it updates applied filters directly.
   */
  const toggleFilter = useCallback(
    (value: string) => {
      const newFilter = appliedFilter.includes(value)
        ? appliedFilter.filter((filter) => filter !== value)
        : [...appliedFilter, value];

      initialFilterClick.current = true;
      isCDP ? requestURLUpdate(newFilter) : setAppliedFilter(newFilter);
    },
    [appliedFilter, isCDP, requestURLUpdate]
  );

  useEffect(() => {
    const filtered = appliedFilter.filter((v) => SEGMENT_FILTERS_SET.has(v));
    if (filtered.length > 0) {
      const pushSegmentation = pushSegmentationFactory({
        location: { collectionHandle },
        // Omit component event if it's not initial value update via click
        omitComponentEvent: !initialFilterClick.current,
      });
      const segmentFilter = SEGMENTS_FILTER_MAP[filtered[filtered.length - 1]];
      if (typeof segmentFilter === 'string') {
        pushSegmentation({ segmentation: segmentFilter });
      } else if (Array.isArray(segmentFilter) && current !== false) {
        const segmentation = (segmentFilter as Array<SegmentationIds>).find(
          (filter) => {
            if (current === 'dogs') {
              return !isCatSegmentation(filter);
            } else {
              return isCatSegmentation(filter);
            }
          }
        );
        segmentation && pushSegmentation({ segmentation: segmentation });
      }
    }
    // pushSegmentationFactory is ignored to avoid infinite loop, which is caused by context implementation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilter, collectionHandle, current]);

  const toggleAllergyFilter = useCallback(
    (value: string) => {
      const newFilter = appliedAllergyFilter.includes(value)
        ? appliedAllergyFilter.filter((filter) => filter !== value)
        : [...appliedAllergyFilter, value];
      setAppliedAllergyFilter(newFilter);
    },
    [appliedAllergyFilter]
  );

  const resetFilter = useCallback(() => {
    isCDP ? requestURLUpdate([]) : setAppliedFilter([]);
    setActiveAllergyFilter([]);
    setAppliedAllergyFilter([]);
  }, [isCDP, requestURLUpdate]);

  const onSortingChange = useCallback(
    (newSorting: CollectionSorting) => {
      setSorting(newSorting);
      let newParams = {};

      if (newSorting !== CollectionSorting.DEFAULT) {
        newParams = {
          sortBy: newSorting,
        };
      }

      const newPath = Array.isArray(router.query.all)
        ? router.query.all.join('/')
        : router.query.all;

      router.push(
        {
          pathname: `/collections/${newPath}`,
          query: {
            ...newParams,
          },
        },
        undefined,
        { shallow: true }
      );
    },
    [setSorting, router]
  );

  // If the products are not displayed in the CDP, we need to filter out the
  // Purpose boxes.
  const filteredVisibleProducts = useMemo(
    () =>
      isCDP
        ? visibleProducts
        : visibleProducts.filter(
            (product) =>
              !(
                product.tags.includes('Probierpaket') ||
                product.tags.includes('Purpose Box') ||
                product.tags.includes('Bundle')
              )
          ),
    [isCDP, visibleProducts]
  );

  return (
    <CollectionContext.Provider
      value={{
        activeAllergyFilter,
        appliedAllergyFilter,
        appliedFilter,
        collection,
        filterConfig,
        isValidating,
        limitOfProductsToShow,
        sorting,
        visibleProducts: filteredVisibleProducts,
        onSortingChange,
        resetFilter,
        setLimitOfProductsToShow,
        toggleActiveAllergyFilter,
        toggleAllergyFilter,
        toggleFilter,
        productsLength: visibleProducts.length,
        bestSellers: bestSellers ?? {},
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

/** Provide collection context  */
export const useCollectionState = () => useContext(CollectionContext);
