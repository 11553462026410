import cn from 'classnames';
import Badge from 'components/badge';
import JudgeMePreviewBadge from 'components/judge-me-preview-badge';
import Price from 'components/price';
import config from 'config';
import { useCommon } from 'contexts/common';
import { useSegmentationContext } from 'contexts/segmentation';
import { useSpecialAbTestContext } from 'contexts/special-ab-test-provider';
import useIsClient from 'hooks/common/use-is-client';
import useProduct from 'hooks/product/use-product';
import usePersonalizedSubtitle from 'hooks/segmentation/use-personalized-subtitle';
import { CollectionProduct } from 'models/collection/collection';
import Image from 'next/image';
import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import hasPersonalizedDiscount from 'utils/has-personalized-discount';
import { shopify } from 'utils/image-loader';
import { minusPercent } from 'utils/product';
import { CARD_TYPES } from '.';
import AccentuateBadges from './accentuate-badges';

interface Props {
  personalizedRibbonText: React.ReactNode;
  product: CollectionProduct;
  showSubscriptionPrice: boolean;
  type: (typeof CARD_TYPES)[keyof typeof CARD_TYPES];
  whiteBackground?: boolean;
  starSize?: 'small' | 'medium';
}

/**
 * Product Card Content. Displays product image, title, rating, price and subscription price.
 */
const Content: React.FC<Props> = ({
  personalizedRibbonText,
  product,
  showSubscriptionPrice,
  type,
  whiteBackground,
  starSize,
}) => {
  const { isClient } = useIsClient();
  const { globalDiscount } = useCommon();
  const hasDiscount = hasPersonalizedDiscount(product, globalDiscount, true);
  const { segmentation } = useSegmentationContext();
  const { alternativeSubtitles } = useSpecialAbTestContext();
  const {
    id: productId,
    metadata: { accentuate = {} } = {},
    availableForSale,
  } = product;

  const { comparePrice, price } = useProduct(product, {
    isSubscriptionSelected: showSubscriptionPrice,
  });

  const subTitle =
    usePersonalizedSubtitle({ tags: product.tags }).subTitle ||
    product.metadata.accentuate.subTitle;

  const shouldShowBadgesSection =
    type !== CARD_TYPES.SLIDER &&
    type !== CARD_TYPES.SLIDER_LARGE &&
    isClient &&
    (personalizedRibbonText || accentuate.ribbonText1);

  const displayTitle = useMemo(() => {
    if (product.metadata.accentuate.mainTitle) {
      return product.metadata.accentuate.mainTitle;
    }

    return product.title;
  }, [product]);

  const alternativeSubtitle =
    (segmentation && alternativeSubtitles?.[productId]?.[segmentation]) ?? null;

  const subscriptionValue = hasDiscount
    ? globalDiscount.discount?.firstSubscriptionOrderDiscount
    : config.subscriptionDiscount;

  const firstCheapestAvailableSubsVariant = useMemo(() => {
    const _firstCheapestAvailableSubsVariant =
      product.getFirstCheapestAvailableSubsVariant();

    return subscriptionValue && _firstCheapestAvailableSubsVariant
      ? minusPercent(
          +_firstCheapestAvailableSubsVariant.priceV2.amount,
          subscriptionValue
        )
      : null;
  }, [product, subscriptionValue]);

  const badgeSection = useMemo(
    () => (
      <>
        <AccentuateBadges
          title={accentuate.ribbonText1}
          subTitle={accentuate.ribbonText2}
          color={accentuate.ribbonColor}
          product={product}
          data-test="accentuate-badges"
        />

        {personalizedRibbonText && (
          <Badge type="Bestseller" data-test="personalized-ribbon-text">
            {personalizedRibbonText}
          </Badge>
        )}
      </>
    ),
    [accentuate, personalizedRibbonText, product]
  );

  const renderWeight = useCallback(
    (weight: string, index: number, arr: string[]) => (
      <React.Fragment key={index}>
        {weight}
        {index < arr.length - 1 ? ' / ' : ''}
      </React.Fragment>
    ),
    []
  );

  return (
    <div
      className={cn(
        'relative min-h-[172px] w-full shadow lg:h-auto lg:pb-4 lg:shadow-none',
        (type === CARD_TYPES.SLIDER || type === CARD_TYPES.SLIDER_LARGE) &&
          'w-full shadow-none',
        type === CARD_TYPES.SLIDER_LARGE && 'bg-white pb-4 lg:pb-5',
        whiteBackground && 'bg-white'
      )}
      data-test="product-card"
    >
      <div
        className={cn(
          'flex h-full gap-2 lg:flex-col lg:gap-2.5',
          (type === CARD_TYPES.SLIDER || type === CARD_TYPES.SLIDER_LARGE) &&
            'flex-col'
        )}
        data-test="product-card-content"
      >
        {shouldShowBadgesSection && type === CARD_TYPES.REGULAR && (
          <div
            className="absolute top-0 z-10 flex w-full gap-1.5 lg:hidden"
            data-test="badges-section"
          >
            {badgeSection}
          </div>
        )}
        {/* Product Image */}
        <div
          className={cn(
            'relative flex items-center justify-center bg-gray-50 p-2.5',
            !availableForSale && 'opacity-70',
            type === CARD_TYPES.SLIDER && 'h-[173px] lg:h-[254px]',
            type === CARD_TYPES.SLIDER_LARGE &&
              'h-full bg-white px-2 py-0 lg:h-[304px] lg:p-4',
            type === CARD_TYPES.REGULAR &&
              'min-h-[122px] min-w-[142px] lg:min-h-[254px] '
          )}
          data-test="product-image-container"
        >
          {shouldShowBadgesSection && type === CARD_TYPES.REGULAR && (
            <div
              className={cn(
                'absolute z-10 hidden w-full gap-1.5 lg:bottom-0 lg:mb-3 lg:flex lg:flex-col lg:items-start lg:gap-1',
                !availableForSale && 'opacity-70'
              )}
              data-test="badges-container"
            >
              {badgeSection}
            </div>
          )}

          {!availableForSale && (
            <Badge
              className="absolute left-[50%] top-[50%] z-10 flex w-full -translate-x-1/2 -translate-y-1/2 items-center justify-center font-medium lg:h-9"
              data-test="out-of-stock-badge"
            >
              <FormattedMessage id={'pdp:buy-panel:product-out-stock'} />
            </Badge>
          )}
          <div
            className={cn(
              'flex items-center justify-center',
              type === CARD_TYPES.SLIDER && 'h-full'
            )}
            data-test="product-image-wrapper"
          >
            {product.image.src && (
              <div className={cn('')} data-test="product-image">
                <div
                  className={cn(
                    type === CARD_TYPES.SLIDER &&
                      'h-[140px] w-[140px] lg:h-[200px] lg:w-[200px]',
                    type === CARD_TYPES.SLIDER_LARGE &&
                      'h-[270px] w-[270px] lg:h-[272px] lg:w-[272px]',
                    type === CARD_TYPES.REGULAR &&
                      'h-[130px] w-[130px] lg:h-[200px] lg:w-[200px]',
                    shouldShowBadgesSection &&
                      type === CARD_TYPES.REGULAR &&
                      'mt-[22px] lg:mt-0'
                  )}
                  data-test="image-container"
                >
                  <Image
                    data-test="product-image-element"
                    loader={shopify}
                    src={product.image.src}
                    alt={product.image.alt || product.title || ''}
                    width={800}
                    height={800}
                    sizes="(min-width: 1024px) 33vw, (min-width: 769px) 50vw, 50vw"
                    className="h-auto"
                    loading="lazy"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/* Product Data */}
        <div
          className={cn(
            'flex flex-col items-start gap-1 bg-white text-left align-top',
            type === CARD_TYPES.REGULAR && 'pr-2 pt-3 lg:pr-0 lg:pt-0',
            type === CARD_TYPES.SLIDER && 'p-0',
            type === CARD_TYPES.SLIDER_LARGE && 'px-3',
            shouldShowBadgesSection &&
              type === CARD_TYPES.REGULAR &&
              'mt-[22px] lg:mt-0'
          )}
          data-test="product-data"
        >
          <section
            className="line-clamp-2 font-black leading-normal text-gray-800"
            data-test="product-title"
          >
            {displayTitle}
          </section>
          {/* Product Rating */}
          <JudgeMePreviewBadge
            productId={productId}
            numberDisplayType="number"
            starSize={starSize}
            data-test="product-rating"
          />
          {/* Product Subtitle */}
          {isClient && (alternativeSubtitle || subTitle) && (
            <div
              data-test="product-subtitle"
              className="line-clamp-2 leading-tight  text-gray-600"
            >
              {alternativeSubtitle ? alternativeSubtitle : subTitle}
            </div>
          )}
          <div
            className={cn(
              'font-black',
              comparePrice ? 'text-red-base' : 'text-gray-800'
            )}
            data-test="product-price"
          >
            {product.variants.length > 1 ? (
              <span data-test="price-from">
                <FormattedMessage id="cdp:general:price_from" />
                &nbsp;
              </span>
            ) : null}
            <span>
              <Price value={firstCheapestAvailableSubsVariant ?? price} />
            </span>
            {comparePrice && (
              <span
                className="ml-2 text-sm font-normal text-gray-600 line-through"
                data-test="compare-price"
              >
                <Price value={comparePrice} />
              </span>
            )}{' '}
          </div>
        </div>

        {type === CARD_TYPES.REGULAR && (
          <p
            className={cn('hidden text-left text-gray-600 lg:line-clamp-2')}
            data-test="product-weights"
          >
            {product.getWeights().map(renderWeight)}
          </p>
        )}
      </div>
    </div>
  );
};

export default memo(Content);
